import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userData: null,
    alert: null,
    token: {
      accessToken: null,
      activeToken: null,
      codeToken: null,
      refreshToken: null,
      resetPasswordToken: null,
    },
    resend: {
      email: null,
      phone: null,
    },
    paymentHistoryDetail: null,
  },
  getters: {
    isLoggedIn(state) {
      return state.userData ? true : false;
    },
    accessToken(state) {
      return state.token.accessToken;
    },
    activeToken(state) {
      return state.token.activeToken;
    },
    codeToken(state) {
      return state.token.codeToken;
    },
    refreshToken(state) {
      return state.token.refreshToken;
    },
    resetPasswordToken(state) {
      return state.token.resetPasswordToken;
    },
  },
  mutations: {
    SET_USERDATA(state, user) {
      state.userData = user;
    },
    SHOW_ALERT(state, payload) {
      state.alert = payload;
    },
    SET_TOKEN(state, payload) {
      if (payload.type === "activeToken") {
        state.token.activeToken = payload.token;
      } else if (payload.type === "accessToken") {
        state.token.accessToken = payload.token;
      } else if (payload.type === "codeToken") {
        state.token.codeToken = payload.token;
      } else if (payload.type === "refreshToken") {
        state.token.refreshToken = payload.token;
      } else {
        state.token.resetPasswordToken = payload.token;
      }
    },
    SAVE_RESEND_INFO(state, payload) {
      state.resend = payload;
    },
    SAVE_DETAIL_PAYMENT_HISTORY(state, payload) {
      state.paymentHistoryDetail = payload;
    },
  },
  actions: {},
  modules: {},
});

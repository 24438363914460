import Vue from "vue";
import store from "@/store/index";
import VueRouter from "vue-router";
import VueBodyClass from "vue-body-class";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      requiredAuth: true,
      headerMenu: false,
      bottomMenu: true,
      title: "Home",
      backButton: false,
      activeMenu: "home",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/LoginPage.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/auth/RegisterPage.vue"),
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("../views/auth/ForgetPasswordPage.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/auth/ResetPasswordPage.vue"),
  },
  {
    path: "/verification-code",
    name: "verification-code",
    component: () => import("../views/auth/VerificationCodePage.vue"),
  },
  {
    path: "/verification-code-reset-password",
    name: "verification-code-reset-password",
    component: () =>
      import("../views/auth/VerificationCodeResetPasswordPage.vue"),
  },
  {
    path: "/two-factor-authentication",
    name: "two-factor-authentication",
    component: () => import("../views/auth/TwoFactorAuthPage.vue"),
  },
  {
    path: "/two-factor-authentication-sms",
    name: "two-factor-authentication-sms",
    component: () => import("../views/auth/TwoFactorAuthSmsPage.vue"),
  },
  {
    path: "/two-factor-authentication-email",
    name: "two-factor-authentication-email",
    component: () => import("../views/auth/TwoFactorAuthEmailPage.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/product/ProductPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: true,
      title: "Produk",
      backButton: false,
      activeMenu: "product",
    },
  },
  {
    path: "/product-offer-sent",
    name: "product-offer-sent",
    component: () => import("../views/product/ProductOfferPage.vue"),
    meta: {
      requiredAuth: true,
      headerMenu: false,
      bottomMenu: false,
      title: "Product",
      backButton: false,
      activeMenu: "product",
    },
  },
  {
    path: "/transaction/auction",
    name: "transaction-auction",
    component: () => import("../views/transaction/AuctionPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: true,
      title: "Transaction",
      backButton: false,
      activeMenu: "transaction",
    },
  },
  {
    path: "/transaction/sell",
    name: "transaction-sell",
    component: () => import("../views/transaction/SellPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: true,
      title: "Transaction",
      backButton: false,
      activeMenu: "transaction",
    },
  },
  {
    path: "/transaction/sell/add",
    name: "transaction-sell-add",
    component: () => import("../views/transaction/SellAddPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: false,
      title: "Sell",
      backButton: true,
      activeMenu: "transaction",
    },
  },
  {
    path: "/transaction/sell/:id",
    name: "transaction-sell-detail",
    component: () => import("../views/transaction/SellDetailPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: false,
      title: "Sell Detail",
      backButton: true,
      activeMenu: "transaction",
    },
  },
  {
    path: "/transaction/buy",
    name: "transaction-buy",
    component: () => import("../views/transaction/BuyPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: true,
      title: "Transaction",
      backButton: false,
      activeMenu: "transaction",
    },
  },
  {
    path: "/notification",
    name: "notification",
    component: () => import("../views/NotificationPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: false,
      title: "Pemberitahuan",
      backButton: true,
      activeMenu: "notification",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/profile/ProfilePage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: true,
      title: "Profile",
      backButton: false,
      activeMenu: "profile",
    },
  },
  {
    path: "/edit-profile",
    name: "edit-profile",
    component: () => import("../views/edit-profile/EditProfilePage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: true,
      title: "Edit Profile",
      backButton: true,
      activeMenu: "profile",
      previousUrl: "/profile",
    },
  },
  {
    path: "/edit-detail",
    name: "edit-detail",
    component: () => import("../views/edit-profile/EditDetailPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: false,
      title: "Change detail",
      backButton: true,
    },
  },
  {
    path: "/edit-password",
    name: "edit-password",
    component: () => import("../views/edit-profile/EditPasswordPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: false,
      title: "Change Password",
      backButton: true,
    },
  },
  {
    path: "/payment-method",
    name: "payment-method",
    component: () => import("../views/profile/PaymentMethodPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: false,
      title: "Payment Method",
      backButton: true,
    },
  },
  {
    path: "/payment-history",
    name: "payment-history",
    component: () => import("../views/profile/PaymentHistoryPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: false,
      title: "Payment History",
      backButton: true,
    },
  },
  {
    path: "/payment-history/detail",
    name: "payment-history-detail",
    component: () => import("../views/profile/PaymentHistoryDetailPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: false,
      title: "Payment Detail",
      backButton: true,
    },
  },
  {
    path: "/usage-history",
    name: "usage-history",
    component: () => import("../views/profile/UsageHistoryPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: false,
      title: "Usage History",
      backButton: true,
    },
  },
  {
    path: "/block-chain-account",
    name: "block-chain-account",
    component: () => import("../views/profile/BlockChainAccountPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: true,
      headerMenu: true,
      bottomMenu: false,
      title: "Block Chain Account",
      backButton: true,
    },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import("../views/help/ContactPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: false,
      headerMenu: true,
      bottomMenu: false,
      title: "Contact Us",
      backButton: true,
    },
  },
  {
    path: "/public/term-of-service",
    name: "public-term-of-service",
    component: () => import("../views/help/TermOfServicePage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: false,
      headerMenu: true,
      bottomMenu: false,
      title: "Term Of Service",
      backButton: false,
    },
  },
  {
    path: "/term-of-service",
    name: "term-of-service",
    component: () => import("../views/help/TermOfServicePage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: false,
      headerMenu: true,
      bottomMenu: false,
      title: "Term Of Service",
      backButton: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../views/help/PrivacyPolicyPage.vue"),
    meta: {
      bodyClass: "has-header",
      requiredAuth: false,
      headerMenu: true,
      bottomMenu: false,
      title: "Privacy Policy",
      backButton: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach(async (to, from, next) => {
  vueBodyClass.guard(to, next);

  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (!store.getters.isLoggedIn) {
      next("/login");
    }
  }
  next();
});

export default router;

<template>
  <div class="button-floating-wrapper">
    <div class="container text-end">
      <router-link to="/transaction/sell/add" class="btn-add"></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddSellButton",
};
</script>

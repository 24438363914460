<template>
  <div>
    <HeaderMenu v-if="$route.meta.headerMenu" />
    <router-view />
    <HeaderNotification v-if="$route.name === 'home'" />
    <AddSellButton v-if="$route.name === 'transaction-sell'" />
    <BottomMenu v-if="$route.meta.bottomMenu" />
  </div>
</template>

<script>
import BottomMenu from "@/components/BottomMenu.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import HeaderNotification from "@/components/HeaderNotification.vue";
import AddSellButton from "@/components/AddSellButton.vue";
import cookie from "js-cookie";

export default {
  components: { BottomMenu, HeaderMenu, HeaderNotification, AddSellButton },
  created() {
    if (cookie.get("token") !== undefined) {
      this.subscribe();
    }
  },
  mounted() {
    // request promtp permission notification
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission !== "granted") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const notification = new Notification(
            "Thanks for allowing notification"
          );
          notification.close();
        }
      });
    }

    this.$options.sockets.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log(data);

      if (data.type === "instant" && this.$store.getters.isLoggedIn) {
        this.$http
          .post("/me/meter-usage", data)
          .then((r) => {
            console.log("success submit ws meter usage", r.data);
          })
          .catch((error) => {
            console.log("error submit ws meter usage", error.response.data);
          });
      }
    };
  },
  methods: {
    subscribe() {
      this.$pusher.subscribe(`private-${this.$store.state.userData.id}`);
      this.$pusher.bind("NOTIFICATION_RECEIVED", (data) => {
        console.log("NOTIFICATION_RECEIVED", data);
        if (data !== undefined) {
          if (
            "Notification" in window &&
            Notification.permission === "granted"
          ) {
            const notification = new Notification(data.title);
            notification.close();
            // notification.onclick = function (event) {
            //   event.preventDefault() // prevent the browser from focusing the Notification's tab
            //   window.open('http://www.mozilla.org', '_blank')
            // }
          }
        }
      });
    },
  },
};
</script>

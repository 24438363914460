<template>
  <div class="topbar">
    <div class="container">
      <div class="d-flex justify-content-end py-3">
        <router-link
          to="/notification"
          class="btn-notification"
          :class="hasNotification ? 'has-notification' : ''"
        >
          <img src="../assets/img/icon/notification.svg" alt="Bell" />
        </router-link>
      </div>
    </div>
  </div>
  <!-- topbar -->
</template>

<script>
export default {
  name: "HeaderNotification",
  data() {
    return {
      hasNotification: false,
    };
  },
  created() {
    this.subscribe();
    this.getUnreadNotification();
  },
  methods: {
    subscribe() {
      this.$pusher.subscribe(`private-${this.$store.state.userData.id}`);
      this.$pusher.bind("NOTIFICATION_RECEIVED", () => {
        this.hasNotification = true;
      });
    },
    getUnreadNotification() {
      this.$http
        .get("notifications/feed")
        .then((response) => {
          const result = response.data;
          this.hasNotification = result.data > 0 ? true : false;
        })
        .catch((error) => {
          this.errorResponse(error);
        });
    },
  },
};
</script>

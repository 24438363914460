<template>
  <div id="header" class="header fixed-top">
    <div class="container header-inner">
      <div class="header-inner-left">
        <template v-if="$route.meta.backButton">
          <button
            v-if="$route.meta.previousUrl === undefined"
            type="button"
            class="btn-plain"
            onclick="history.back();"
          >
            <span class="btn-back icon-chevron-left"></span>
          </button>
          <button
            v-else
            type="button"
            class="btn-plain"
            @click="$router.push($route.meta.previousUrl)"
          >
            <span class="btn-back icon-chevron-left"></span>
          </button>
        </template>
      </div>
      <div class="header-inner-center">
        <div class="body-2 fw-medium">
          {{ $route.meta.title }}
        </div>
      </div>
      <div class="header-inner-right"></div>
    </div>
  </div>
  <!-- header -->
</template>

<script>
export default {
  name: "HeaderProfile",
};
</script>

<template>
  <div class="nav-toolbar">
    <div class="container px-0">
      <div class="nav-toolbar-inner">
        <router-link
          to="/"
          class="nav-toolbar-link"
          :class="$route.meta.activeMenu === 'home' ? 'active' : ''"
        >
          <span class="nav-toolbar-icon icon-home"></span>
          <span>Beranda</span>
        </router-link>
        <router-link
          to="/product"
          class="nav-toolbar-link"
          :class="$route.meta.activeMenu === 'product' ? 'active' : ''"
        >
          <span class="nav-toolbar-icon icon-product"></span>
          <span>Produk</span>
        </router-link>
        <router-link
          to="/transaction/auction"
          class="nav-toolbar-link"
          :class="$route.meta.activeMenu === 'transaction' ? 'active' : ''"
        >
          <span class="nav-toolbar-icon icon-transaction"></span>
          <span>Transaksi</span>
        </router-link>
        <router-link
          to="/profile"
          class="nav-toolbar-link"
          :class="$route.meta.activeMenu === 'profile' ? 'active' : ''"
        >
          <span class="nav-toolbar-icon icon-profile"></span>
          <span>Profil</span>
        </router-link>
      </div>
    </div>
  </div>
  <!-- nav-toolbar -->
</template>

<script>
export default {
  name: "BottomMenu",
};
</script>

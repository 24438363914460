/* eslint-disable vue/multi-word-component-names */
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./utils/mixin";
import "./utils/filter";
import "./assets/custom.scss";
import router from "./router";
import store from "./store";
import axios from "axios";
import cookie from "js-cookie";
import Pusher from "pusher-js";
import VueNativeSock from "vue-native-websocket";

// Global register
import Snackbar from "vuejs-snackbar";
Vue.component("snackbar", Snackbar);

import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);

import InfiniteLoading from "vue-infinite-loading";
Vue.use(InfiniteLoading);

Vue.use(VueNativeSock, process.env.VUE_APP_SOCKER_URL, {
  format: "json",
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
});

// setup axios instance
const axiosOptions = {
  baseURL: process.env.VUE_APP_BASE_URL_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-BLOCKCHAIN": "true",
  },
};

if (cookie.get("token") !== undefined) {
  const token = JSON.parse(cookie.get("token"));
  store.commit("SET_TOKEN", {
    type: "accessToken",
    token: token.accessToken,
  });
  store.commit("SET_TOKEN", {
    type: "refreshToken",
    token: token.refreshToken,
  });
  axiosOptions.headers.Authorization = `Bearer ${token.accessToken}`;

  // get user data
  await axios
    .get(`${process.env.VUE_APP_BASE_URL_API}/me`, {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        "X-BLOCKCHAIN": "true",
      },
    })
    .then((response) => {
      const result = response.data;
      store.commit("SET_USERDATA", result.data);
    })
    .catch((error) => {
      console.log(error);
    });

  const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    channelAuthorization: {
      endpoint: `${process.env.VUE_APP_BASE_URL_API}/me/pusher/auth`,
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        "X-BLOCKCHAIN": "true",
      },
    },
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  });
  Vue.prototype.$pusher = pusher;
}

const axiosInstance = axios.create(axiosOptions);
Vue.prototype.$http = axiosInstance;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
